/**
 * module utils.js
 */

import axios from '@/plugins/axios.js'

const getAppDetail = async (detailId) => {
  const rest = await axios.get(`/api/app_admin/app/${detailId}/`)
  console.log('rest', rest)
  return rest.data
}
export {
  getAppDetail
}
