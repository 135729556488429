var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%","elevation":"0"}},[_c('v-card-title',{staticClass:"py-0 my-0"},[_c('v-text-field',{attrs:{"append-icon":"","label":"Filtra","single-line":"","hide-details":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadAppUsers.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.activeClientApp.admin_level === 100)?_c('v-switch',{staticClass:"mx-2",attrs:{"label":'Mostra ' +(_vm.perApp ? ("solo " + (_vm.activeClientApp.name)) : 'Tutti')},model:{value:(_vm.perApp),callback:function ($$v) {_vm.perApp=$$v},expression:"perApp"}}):_vm._e(),(_vm.activeClientApp.admin_level === 100)?_c('v-switch',{staticClass:"mx-2",attrs:{"label":("Orfani " + (_vm.orphans ? 'Si' : 'No'))},model:{value:(_vm.orphans),callback:function ($$v) {_vm.orphans=$$v},expression:"orphans"}}):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":_vm.loadAppUsers}},on),[_c('v-icon',[_vm._v("mdi-refresh-circle")])],1)]}}])},[_c('span',[_vm._v("Ricarica la lista degli utenti")])])],1),_c('v-data-table',{staticClass:"user-table",attrs:{"fixed-header":"","height":"calc( 100vh - 340px )","headers":_vm.headers,"items":_vm.users,"search":_vm.search,"sort-by":['username'],"multi-sort":"","color":"primary","footer-props":_vm.footerProps,"options":_vm.options,"server-items-length":_vm.pagination.total},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Modifica utente")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.mapped)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-map-marker ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Questo utente ha delle mappature")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.this_only)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteUser(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Questo utente è collegato solo a queta applicazione e può essere cancellato")])])]}}])}),_c('v-dialog',{attrs:{"width":"80%","persistent":""},model:{value:(_vm.showEdit),callback:function ($$v) {_vm.showEdit=$$v},expression:"showEdit"}},[_c('user-details',{ref:"userDetails",on:{"closed":_vm.closed,"reload":_vm.loadAppUsers}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }