<template>
  <v-card
    width="100%"
    elevation="0"
  >
    <v-card-title
      class="py-0 my-0"
    >
      <v-text-field
        v-model="search"
        append-icon=""
        label="Filtra"
        single-line
        hide-details
        clearable
        @keyup.enter="loadAppUsers"
      />
      <v-switch
        v-if="activeClientApp.admin_level === 100"
        v-model="perApp"
        class="mx-2"
        :label="'Mostra ' +(perApp ? `solo ${activeClientApp.name}` : 'Tutti')"
      />
      <v-switch
        v-if="activeClientApp.admin_level === 100"
        v-model="orphans"
        class="mx-2"
        :label="`Orfani ${ orphans ? 'Si' : 'No'}`"
      />
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            icon
            color="primary"
            @click="loadAppUsers"
            v-on="on"
          >
            <v-icon>mdi-refresh-circle</v-icon>
          </v-btn>
        </template>
        <span>Ricarica la lista degli utenti</span>
      </v-tooltip>
    </v-card-title>
    <v-data-table
      fixed-header
      height="calc( 100vh - 340px )"
      :headers="headers"
      :items="users"
      :search="search"
      :sort-by="['username']"
      class="user-table"
      multi-sort
      color="primary"
      :footer-props="footerProps"
      :options.sync="options"
      :server-items-length="pagination.total"
    >
      <template #item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              color="success"
              @click="editItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Modifica utente</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="primary"
              v-if="item.mapped"
              v-bind="attrs"
              v-on="on"
            >
              mdi-map-marker
            </v-icon>
          </template>
          <span>Questo utente ha delle mappature</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="error"
              v-if="item.this_only"
              @click="deleteUser(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Questo utente è collegato solo a queta applicazione e può essere cancellato</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showEdit"
      width="80%"
      persistent
    >
      <user-details
        ref="userDetails"
        @closed="closed"
        @reload="loadAppUsers"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  components: {
    UserDetails: () => import('./AppUsersDetail.vue')
  },
  data () {
    return {
      page: 0,
      totalUsers: 0,
      options: {},
      showEdit: 0,
      perApp: true,
      orphans: false,
      selectedItem: null,
      mappables: [],
      pagination: {
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: null,
        next_page_url: null,
        previous_page_url: null,
        first: null,
        last: null
      },
      footerProps: {
        dark: true,
        color: 'purple',
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 150, 200, 250, 300, -1]
      },
      headers: [
        { text: 'Username', value: 'fullname' },
        { text: 'Email', value: 'email' },
        { text: 'Accounts', value: 'account_names' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      users: [],
      search: ''
    }
  },
  computed: {
    ...mapState('appAdmin', ['activeClientApp'])
  },
  methods: {
    loadAppUsers () {
      console.log('Reloading....')
      this.$http.get(`/api/app_admin/app/${this.activeClientApp.app_id}/aau/`,
        {
          params: {
            per_page: this.options.itemsPerPage,
            page: this.options.page,
            search: this.search,
            orphans: this.orphans,
            per_app: this.perApp
          }
        }
      )
        .then(r => {
          this.users = r.data.data
          this.pagination.total = r.data.pagination.total
        })
    },
    editItem (item) {
      this.selectedItem = item
      this.showEdit = 1
      this.$nextTick(() => {
        this.$refs.userDetails.loadUserDetails(item)
      })
    },
    async deleteUser (item) {
      const res = await this.$dialog.prompt({
        title: `Eliminare l'account "${item.username}" per l'applicazione "${this.activeClientApp.name}" ?`,
        text: 'Digita il nome dell\'account per confermare l\'eliminazione',
        type: 'warning',
        icon: 'mdi-alert',
        class: ['sso-dash-prompt-dialog'],
        actions: {
          false: {
            outlined: true,
            color: 'primary',
            text: 'Annulla'
          },
          true: {
            outlined: true,
            color: 'error',
            text: 'Elimina'
          }
        }
      })
      if (res === item.username) {
        this.$http.delete(`/api/app_admin/app/${this.activeClientApp.app_id}/aau/${item.id}/`)
          .then(() => {
            this.loadAppUsers()
          })
      } else if (res) {
        this.$dialog.warning({
          text: 'l\'username non corrisponde.',
          title: 'App User',
          icon: 'mdi-alert',
          type: 'warning'
        })
      }
    },
    closed () {
      this.showEdit = 0
    }
  },
  mounted () {
    global.appusers = this
    this.loadAppUsers()
  },
  watch: {
    activeClientApp (newValue, oldValue) {
      this.loadAppUsers()
    },
    options: {
      handler () {
        this.loadAppUsers()
      },
      deep: true
    },
    perApp () {
      this.loadAppUsers()
    },
    orphans () {
      this.loadAppUsers()
    }
  }
}
</script>
<style lang="scss">
  .sso-dash-prompt-dialog {
    .v-card__title{
      word-break: break-word;
    }
  }
  .user-table {
    .v-input__control {
      width: 7rem;
    }
    .v-data-footer {
      .v-btn__content {
        color: black;
      }
    }
  }
</style>
