<template>
  <div
    v-if="app"
    style="width: 100%;"
  >
    <v-form
      class="app-settings-form"
      v-model="valid"
      style="width: 100%;"
    >
      <v-row>
        <v-col>
          <v-text-field
            v-model="app.application.name"
            label="Nome applicazione"
            required
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="app.description"
            label="Descrizione"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="app.login_url"
            label="URL di login"
            required
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="app.login_next"
            label="Url di landing dopo il login (es. profile)"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="app.login_validation"
            label="URL per la validazione del login"
          />
          <v-radio-group
            v-model="app.login_validation_method"
            label="motodo per validazione login"
            dense
            row
          >
            <v-radio
              label="get"
              value="get"
            />
            <v-radio
              label="post"
              value="post"
            />
            <v-radio
              label="json"
              value="json"
            />
          </v-radio-group>
        </v-col>
        <v-col>
          <v-text-field
            v-model="app.user_info"
            label="Url per la richiesta info per l'utente"
          />
          <v-radio-group
            v-model="app.user_info_method"
            label="motodo per info utente"
            dense
            row
          >
            <v-radio
              label="get"
              value="get"
            />
            <v-radio
              label="post"
              value="post"
            />
            <v-radio
              label="json"
              value="json"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group
            v-model="app.register_url"
            label="Pagina di registrazione"
            dense
            row
          >
            <v-radio
              label="RegisterWizard"
              value="/vaccount/registerwizard"
            />
            <v-radio
              label="Register"
              value="/vaccount/register"
            />
          </v-radio-group>
        </v-col>
        <v-col>
          <v-row
            align="center"
          >
            <legend
              class="v-label theme--light mr-2"
              style="position: relative;"
            >
              Tipo registrazione
            </legend>
            <v-checkbox
              v-model="regType"
              label="Account"
              value="account"
              class=""
              dense
            />
            <v-checkbox
              v-model="regType"
              label="Codice validazione"
              value="vcode"
              class="ml-3"
              dense
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="app.single_user"
            label="Utente singolo"
            permanent-hint
            hint="EpSso invia all'app solo l'account di default o quello selezionato e non tutta la lista degli account associati"
            dense
          />
        </v-col>
        <v-col>
          <v-checkbox
            v-model="app.check_if_not_registered"
            label="Verifica in registrazione"
            dense
          />
        </v-col>
        <v-col>
          <v-checkbox
            v-model="app.unique_for_app"
            label="Associazione uno a uno"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            label="Login disclaimer"
            v-model="app.login_disclaimer"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            label="Register disclaimer"
            v-model="app.register_disclaimer"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            label="Register info"
            v-model="app.register_info"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            label="Add account info"
            v-model="app.add_account_info"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row
      justify="end"
    >
      <v-btn
        color="accent"
        @click="loadAppDetail"
      >
        Annulla
      </v-btn>
      <v-btn
        color="primary"
        @click="saveSetting"
      >
        Salva
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getAppDetail } from './utils.js'
export default {
  data () {
    return {
      valid: false,
      app: null
    }
  },
  computed: {
    ...mapState('appAdmin', ['activeClientApp']),
    regType: {
      get () {
        return this.app.reg_type.split(',').map(r => r.trim())
      },
      set (value) {
        this.app.reg_type = value.join(',')
      }
    }
  },
  methods: {
    async loadAppDetail () {
      this.app = await getAppDetail(this.activeClientApp.id)
    },
    saveSetting () {
      this.$http.patch(`/api/app_admin/app/${this.app.id}/`, this.app)
        .then(r => {
          this.app = r.data
          this.$dialog.notify.success('Impostazioni salvate con successo')
        })
        .catch(e => {
          this.$msgError(e.response.data, 'AppAdmin')
          console.error(e)
        })
    }
  },
  mounted () {
    this.loadAppDetail()
  },
  watch: {
    activeClientApp (newValue, oldValue) {
      console.log('watch', newValue, oldValue)
      this.loadAppDetail()
    }
  }
}
</script>
<style lang="scss">
.app-info {
  flex: 1;
}
.app-settings-form {
  max-height: calc( 100vh - 270px );
  height: calc( 100vh - 270px );
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
