<template>
  <v-card
    width="100%"
    elevation="0"
  >
    <v-row>
      <v-col
        cols="5"
      >
        <v-file-input
          v-model="fileobj"
          show-size
          accept=".csv"
          label="Clicca qui per selezionare un file da caricare"
          prepend-icon="mdi-file-import"
          truncate-length="150"
          @change="upload"
        />
      </v-col>
      <v-col>
        <v-checkbox
          v-model="dryRun"
          color="green"
          :label="`Simulazione: ${dryRun ? 'Si': 'No'}`"
        />
      </v-col>
      <v-col>
        <v-checkbox
          v-model="forcePassword"
          color="red"
          :label="`Forza la password: ${forcePassword ? 'Si': 'No'}`"
        />
      </v-col>
      <v-col cols="2">
        <v-btn
          @click="doImport"
          color="primary"
          :disabled="!fileobj"
        >
          Importa
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="showLog"
      fixed-header
      hide-default-header
      height="calc( 100vh - 360px )"
      :headers="logHeaders"
      :items="log"
      class="user-table log"
      color="primary"
      :footer-props="footerProps"
      :item-class="logColor"
    />
    <v-data-table
      v-else
      fixed-header
      height="calc( 100vh - 360px )"
      :headers="headers"
      :items="users"
      :sort-by="['username']"
      no-sort-desc="[false, true]"
      class="user-table"
      multi-sort
      color="primary"
      :footer-props="footerProps"
    />
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      fileobj: undefined,
      progress: 0,
      showLog: false,
      showProgress: false,
      dryRun: true,
      forcePassword: false,
      footerProps: {
        dark: true,
        color: 'purple',
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 150, 200, 250, 300, -1]
      },
      headers: [
        { text: 'Username', value: 'username' },
        { text: 'Email', value: 'email' },
        { text: 'Account', value: 'account' }
      ],
      users: [],
      log: [],
      logHeaders: [
        { text: 'actime', value: 'actime', width: '455px' },
        // { text: 'tipo', value: 'type' },
        { text: 'messaggio', value: 'msg' }
      ]
    }
  },
  computed: {
    ...mapState('appAdmin', ['activeClientApp'])
  },
  methods: {
    logColor (item) {
      return `${item.type}--text`
    },
    upload () {
      this.users = []
      this.showLog = false
      if (!this.fileobj) { return }
      this.showProgress = true
      this.progress = 0
      this.$http.upload(
        `/api/app_admin/app/user_import/${this.activeClientApp.app_id}/`,
        this.fileobj,
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        .then(r => {
          this.showProgress = false
          this.users = r.data
        })
        .catch(e => {
          this.$msgError(e)
        })
    },
    doImport () {
      if (!this.fileobj) {
        this.$msgInfo('Selezionare un file')
        return
      }
      this.progress = 0
      this.showProgress = true
      this.$http.post(
        `/api/app_admin/app/user_import/${this.activeClientApp.app_id}/`,
        { filename: this.fileobj.name, dry_run: this.dryRun, force_password: this.forcePassword },
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        .then(r => {
          this.showLog = true
          this.showProgress = false
          this.log = r.data
        })
        .catch(e => {
          this.$msgError(e)
        })
    }
  }
}
</script>
<style lang="scss">
  .sso-dash-prompt-dialog {
    .v-card__title{
      word-break: break-word;
    }
  }
  .user-table {
    &.log {
      font-family: monospace;
    }
    .v-input__control {
      width: 7rem;
    }
    .v-data-footer {
      .v-btn__content {
        color: black;
      }
    }
  }
</style>
