<template>
  <v-card
    class="app-admin"
    width="100%"
  >
    <v-row
      class="fill-height"
      justify="start"
    >
      <v-navigation-drawer
        disable-router-watcher
        width="180"
        class="elevation-0"
        flat
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
      >
        <sso-client-app-list
          v-model="selectedApp"
          :img-click="() => mini = !mini"
        />
        <v-list
          dense
          shaped
        >
          <v-list-item-group
            v-model="menuItem"
            mandatory
          >
            <v-list-item
              class="ml-1"
              v-for="item in items"
              :to="item.to"
              :key="item.title"
              link
            >
              <v-list-item-icon
                class="mr-2"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-card
        class="fill-height px-3 app-admin-view"
      >
        <v-list-item
          class="app-info-head"
          two-line
        >
          <v-list-item-avatar
            class="profile"
            size="64"
          >
            <img
              alt="App"
              :src="selectedApp.logo"
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h5">
              {{ selectedApp.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ selectedApp.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-fade-transition mode="out-in">
          <v-container
            class="fill-height sso-container"
            fluid
          >
            <router-view />
          </v-container>
        </v-fade-transition>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      drawer: true,
      mini: false,
      menuItem: 0,
      selectedApp: {
        id: null,
        name: null,
        description: null,
        logo: null
      }
    }
  },
  computed: {
    ...mapState('account', ['user']),
    items () {
      return [
        { title: 'Utenti', icon: 'mdi-account-group-outline', to: { name: 'AppAdmin::Users' } },
        { title: 'Impostazioni', icon: 'mdi-cog', to: { name: 'AppAdmin::Settings' } },
        { title: 'Import utenti', icon: 'mdi-file-import', to: { name: 'AppAdmin::UserImport' } }
      ]
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss">
.app-info-head {
    height: 85px;
    width: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(80, 113, 142, 0.6));
}
.app-admin {
  .app-admin-view {
    flex: 1;
    .sso-container {
      max-height: calc( 100vh - 200px );
      height: calc( 100vh - 200px );
      overflow-y: auto;
    }
  }
}
</style>
